import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";
import {MatSnackBar, MatDialog} from "@angular/material";
import {AuthenticationService} from "../../services/authentication.service";
import {ApiService} from "../../api/api.service";
import {District} from "../../dataModules/district";
import {TwoValueDialogComponent} from "../../dialog/two-value-dialog/two-value-dialog.component";

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.css']
})
export class DistrictComponent extends BaseComponent {
  districts: District[];
  displayedColumns: string[] = ['id', 'name'];

  constructor(public dialog: MatDialog, private apiService: ApiService, snackBar: MatSnackBar, service: AuthenticationService) {
    super(snackBar, service);
  }

  myNgOnInit() {
    this.loadDistrict();
  }

  loadDistrict() {
    this.apiService.getDistricts().subscribe(districts=> {
      this.districts = districts;
      this._service.districts = this.districts;
    });
  }

  add() {
    const dialogRef = this.dialog.open(TwoValueDialogComponent, {
      width: '250px',
      data: {title: "Group"}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.addDistrict(result);
      }
    });
  }

  addDistrict(name) {
    this.apiService.addDistrict(name).subscribe(res=> {
      if (res['result'] == 0) {
        this.openSnackBar("Add New Group Fail");
        return;
      }

      this.loadDistrict();
      this.openSnackBar("Add New Group Success");
    });
  }
}
