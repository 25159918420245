import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {AuthenticationService} from "../../services/authentication.service";
import {d} from "@angular/core/src/render3";

@Component({
  selector: 'app-game-user-dialog',
  templateUrl: './game-user-dialog.component.html',
  styleUrls: ['./game-user-dialog.component.css']
})
export class GameUserDialogComponent {
  phoneE = false;
  disE = false;
  idE = false;
  nameE = false;
  pwE = false;
  isDelete = false;
  acc = {user_id: null, phone_num: null, username: "", password: "", district_id: null, status_id: 1};
  // selectedClubs = [];
  isUpdate = false;

  constructor(public dialogRef: MatDialogRef<GameUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data, public service: AuthenticationService) {
    // this.selectedClubs = new Array<boolean>(service.clubs.length);
    if (data.gameUser != null) {
      this.isUpdate = true;
      var index = 0;
      // for (var i = 0; i < service.clubs.length; i++) {
      //   if (data.gameUser.clubs[index].club_id == service.clubs[i]._id) {
      //     this.selectedClubs[i] = true;
      //     index++;
      //     if (data.gameUser.clubs.length == index) {
      //       break;
      //     }
      //   }
      // }

      this.acc = {
        user_id: data.gameUser.user_id,
        username: data.gameUser.username,
        password: data.gameUser.password,
        district_id: data.gameUser.district_id,
        phone_num: data.gameUser.phone_num,
        status_id: 1
        // clubs: []
      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.isDelete = true;
  }

  onCancelDelete(): void {
    this.isDelete = false;
  }

  onConfirmDelete(): void {
    this.acc.status_id = -1;
    this.dialogRef.close(this.acc);
    // this.dialogRef.close();
  }


  onEnterClick(): void {
    this.phoneE = false;
    this.disE = false;
    this.idE = false;
    this.nameE = false;
    this.pwE = false;
    var valid = true;

    if (this.acc.phone_num == null || this.acc.phone_num == 0) {
      this.phoneE = true;
      valid = false;
    }

    if (this.acc.user_id == null || this.acc.user_id == 0) {
      this.idE = true;
      valid = false;
    }
    if (this.acc.username.length == 0) {
      this.nameE = true;
      valid = false;
    }
    if (this.acc.password.length == 0) {
      this.pwE = true;
      valid = false;
    }
    if (this.acc.district_id == null || this.acc.district_id == 0) {
      this.disE = true;
      valid = false;
    }


    if (!valid) {
      return;
    }

    this.dialogRef.close(this.acc);
  }


}
